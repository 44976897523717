import React from 'react';
import styled from 'styled-components';
import { Icon } from 'components/atoms';
import 'App.scss';

const contactMedia = [
  {
    iconName: 'EmailColorIcon',
    link: 'mailto:contact@samuelumeh.com',
  },
  {
    iconName: 'LinkedInColorIcon',
    link: 'https://www.linkedin.com/in/samuel-e-umeh/',
  },
  {
    iconName: 'XingColorIcon',
    link: 'https://www.xing.com/profile/Samuel_Umeh',
  },
  {
    iconName: 'TwitterColorIcon',
    link: 'https://twitter.com/sammyeby',
  },
];

const StyledContactLink = styled.a`
  margin: 0 15px;
  transition: all 0.3s ease 0s;
  &:hover {
    transform: translateY(-7px);
  }
`;

const App = () => (
  <div className="App">
    <div className="App__headline">
      <h2>Coming soon!!</h2>
    </div>
    <div className="App__icons">
      <Icon name="UnderConstructionColorIcon" height="150px" width="150px" />
      <Icon name="BarrierColorIcon" height="150px" width="150px" />
    </div>
    <div className="App__text">
      <div className="App__text__info">
        In the mean time, you can get in touch with me via:
      </div>
      <div className="App__text__links">
        {contactMedia.map(m => (
          <StyledContactLink href={m.link} key={m.link} target="_blank">
            <Icon name={m.iconName} height="40px" width="40px" />
          </StyledContactLink>
        ))}
      </div>
    </div>
  </div>
);

export default App;
