import React from 'react';
import { string } from 'prop-types';
import * as Icons from './icons';

const Icon = ({ name, ...rest }) => {
  const SelectedIcon = Icons[name];
  return SelectedIcon ? (
    <SelectedIcon {...{ style: { display: 'block' }, ...rest }} />
  ) : null;
};

Icon.propTypes = {
  name: string.isRequired,
};

export default Icon;
